<template>
  <div>
    <b-row>
      <b-col lg="2">
        <b-card-header
          header="Sistema"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.ConfiguracoesSistema" @change="marcarDesmarcarHandler('Sistema')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <hr />
    </b-row>
    <b-row class="mx-0 mb-1" v-show="optionsLocal.ConfiguracoesSistema">
      <b-tabs class="w-100">
        <b-tab active>
          <template #title>
            <b-icon icon="list-check" class="mr-50" size="18"></b-icon>
            <span class="font-weight-bold">Parâmetros do Sistema</span>
          </template>
          <b-row>
            <b-col lg="2">
              <b-card-header
                header="Composição de taxas"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaCompTaxas"
                      @change="marcarDesmarcarHandler('Composição de taxas')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaCompTaxas"
                      v-model="optionsLocal.ConfiguracoesSistemaCompTaxasEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Parâmetros de Arrecadação"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaParametroArrecadacao"
                      @change="marcarDesmarcarHandler('Parâmetros de Arrecadação')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaParametroArrecadacao"
                      v-model="optionsLocal.ConfiguracoesSistemaParametroArrecadacaoEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Critérios de Elegibilidade"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaCriteriosElegibilidade"
                      @change="marcarDesmarcarHandler('Critérios de Elegibilidade')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaCriteriosElegibilidade"
                      v-model="optionsLocal.ConfiguracoesSistemaCriteriosElegibilidadeEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Indice de Referência"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaIndiceRef"
                      @change="marcarDesmarcarHandler('Indice de Referência')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaIndiceRef"
                      v-model="optionsLocal.ConfiguracoesSistemaIndiceRefConsultar"
                      >Consultar</b-form-checkbox
                    >
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaIndiceRef"
                      v-model="optionsLocal.ConfiguracoesSistemaIndiceRefEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaIndiceRef"
                      v-model="optionsLocal.ConfiguracoesSistemaIndiceRefCriarNovo"
                      >Criar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Restrições"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaRestricao"
                      @change="marcarDesmarcarHandler('Restrições')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaRestricao"
                      v-model="optionsLocal.ConfiguracoesSistemaRestricaoEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Restrições RPPS"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaRestricaoRPPS"
                      @change="marcarDesmarcarHandler('Restrições RPPS')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaRestricaoRPPS"
                      v-model="optionsLocal.ConfiguracoesSistemaRestricaoRPPSEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="PenToolIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Personalização do Sistema</span>
          </template>
          <b-row>
            <b-col lg="2">
              <b-card-header
                header="Notificações"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaNotificacoes"
                      @change="marcarDesmarcarHandler('Notificações')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaNotificacoes"
                      v-model="optionsLocal.ConfiguracoesSistemaNotificacoesEditar"
                      >Nova Notificação</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Personalização"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaPersonalizacao"
                      @change="marcarDesmarcarHandler('Personalização')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaPersonalizacao"
                      v-model="optionsLocal.ConfiguracoesSistemaPersonalizacaoEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Aplicativo"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaAplicativo"
                      @change="marcarDesmarcarHandler('Aplicativo')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaAplicativo"
                      v-model="optionsLocal.ConfiguracoesSistemaAplicativoEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col lg="2">
              <b-card-header
                header="Politica de Senhas"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaPoliticaSenhas"
                      @change="marcarDesmarcarHandler('Politica Senhas')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>
                  <div style="border: 1px solid" class="mb-1"></div>
                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaPoliticaSenhas"
                      v-model="optionsLocal.ConfiguracoesSistemaPoliticaSenhasEditar"
                      >Editar</b-form-checkbox
                    >
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col lg="2">
              <b-card-header
                header="Lista de Pendências"
                header-bg-variant="primary"
                header-text-variant="white"
                header-class="text-center text-lg p-1"
              />
              <b-card>
                <b-card-text>
                  <b-form-group class="mb-1">
                    <b-form-checkbox
                      v-model="optionsLocal.ConfiguracoesSistemaListaPendencias"
                      @change="marcarDesmarcarHandler('Lista de Pendências')"
                    >
                      Acessar
                    </b-form-checkbox>
                  </b-form-group>

                  <div style="border: 1px solid" class="mb-1"></div>

                  <b-form-group>
                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaListaPendencias"
                      v-model="optionsLocal.ConfiguracoesSistemaListaPendenciasCadastrar"
                    >
                      Cadastrar
                    </b-form-checkbox>

                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaListaPendencias"
                      v-model="optionsLocal.ConfiguracoesSistemaListaPendenciasEditar"
                    >
                      Editar
                    </b-form-checkbox>

                    <b-form-checkbox
                      :disabled="!optionsLocal.ConfiguracoesSistemaListaPendencias"
                      v-model="optionsLocal.ConfiguracoesSistemaListaPendenciasExcluir"
                    >
                      Excluir
                    </b-form-checkbox>
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      optionsLocal: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CConfiguracoesSistema() {
        const value = this.optionsLocal.ConfiguracoesSistema
        this.optionsLocal.ConfiguracoesSistema = value

        this.optionsLocal.ConfiguracoesSistemaCompTaxas = value
        this.optionsLocal.ConfiguracoesSistemaCompTaxasEditar = value

        this.optionsLocal.ConfiguracoesSistemaParametroArrecadacao = value
        this.optionsLocal.ConfiguracoesSistemaParametroArrecadacaoEditar = value

        this.optionsLocal.ConfiguracoesSistemaCriteriosElegibilidade = value
        this.optionsLocal.ConfiguracoesSistemaCriteriosElegibilidadeEditar = value

        this.optionsLocal.ConfiguracoesSistemaIndiceRef = value
        this.optionsLocal.ConfiguracoesSistemaIndiceRefConsultar = value
        this.optionsLocal.ConfiguracoesSistemaIndiceRefEditar = value
        this.optionsLocal.ConfiguracoesSistemaIndiceRefCriarNovo = value

        this.optionsLocal.ConfiguracoesSistemaRestricao = value
        this.optionsLocal.ConfiguracoesSistemaRestricaoEditar = value

        this.optionsLocal.ConfiguracoesSistemaRestricaoRPPS = value
        this.optionsLocal.ConfiguracoesSistemaRestricaoRPPSEditar = value

        this.optionsLocal.ConfiguracoesSistemaPersonalizacao = value
        this.optionsLocal.ConfiguracoesSistemaPersonalizacaoEditar = value

        this.optionsLocal.ConfiguracoesSistemaAplicativo = value
        this.optionsLocal.ConfiguracoesSistemaAplicativoEditar = value

        this.optionsLocal.ConfiguracoesSistemaPoliticaSenhas = value
        this.optionsLocal.ConfiguracoesSistemaPoliticaSenhasEditar = value

        this.optionsLocal.ConfiguracoesSistemaListaPendencias = value
        this.optionsLocal.ConfiguracoesSistemaListaPendenciasCadastrar = value
        this.optionsLocal.ConfiguracoesSistemaListaPendenciasEditar = value
        this.optionsLocal.ConfiguracoesSistemaListaPendenciasExcluir = value

        this.optionsLocal.ConfiguracoesSistemaNotificacoes = value
        this.optionsLocal.ConfiguracoesSistemaNotificacoesEditar = value

        this.updateGeneralData()
      },
      CComposicaoTaxas() {
        const value = this.optionsLocal.ConfiguracoesSistemaCompTaxas
        this.optionsLocal.ConfiguracoesSistemaCompTaxasEditar = value
        this.updateGeneralData()
      },
      CParametroArrecadacao() {
        const value = this.optionsLocal.ConfiguracoesSistemaParametroArrecadacao
        this.optionsLocal.ConfiguracoesSistemaParametroArrecadacaoEditar = value
        this.updateGeneralData()
      },
      CCriteriosElegibilidade() {
        const value = this.optionsLocal.ConfiguracoesSistemaCriteriosElegibilidade
        this.optionsLocal.ConfiguracoesSistemaCriteriosElegibilidadeEditar = value
        this.updateGeneralData()
      },
      CIndiceRef() {
        const value = this.optionsLocal.ConfiguracoesSistemaIndiceRef
        this.optionsLocal.ConfiguracoesSistemaIndiceRefConsultar = value
        this.optionsLocal.ConfiguracoesSistemaIndiceRefEditar = value
        this.optionsLocal.ConfiguracoesSistemaIndiceRefCriarNovo = value
        this.updateGeneralData()
      },
      CRestricoes() {
        const value = this.optionsLocal.ConfiguracoesSistemaRestricao
        this.optionsLocal.ConfiguracoesSistemaRestricaoEditar = value
        this.updateGeneralData()
      },
      CRestricoesRPPS() {
        const value = this.optionsLocal.ConfiguracoesSistemaRestricaoRPPS
        this.optionsLocal.ConfiguracoesSistemaRestricaoRPPSEditar = value
        this.updateGeneralData()
      },
      CConfiguracoesAplicativo() {
        const value = this.optionsLocal.ConfiguracoesSistemaAplicativo

        this.optionsLocal.ConfiguracoesSistemaAplicativoEditar = value

        this.updateGeneralData()
      },
      CConfiguracoesPoliticaSenhas() {
        const value = this.optionsLocal.ConfiguracoesSistemaPoliticaSenhas

        this.optionsLocal.ConfiguracoesSistemaPoliticaSenhasEditar = value

        this.updateGeneralData()
      },
      CConfiguracoesListaPendencias() {
        const value = this.optionsLocal.ConfiguracoesSistemaListaPendencias

        this.optionsLocal.ConfiguracoesSistemaListaPendenciasCadastrar = value
        this.optionsLocal.ConfiguracoesSistemaListaPendenciasEditar = value
        this.optionsLocal.ConfiguracoesSistemaListaPendenciasExcluir = value

        this.updateGeneralData()
      },
      CConfiguracoesPersonalizacao() {
        const value = this.optionsLocal.ConfiguracoesSistemaPersonalizacao
        this.optionsLocal.ConfiguracoesSistemaPersonalizacaoEditar = value
        this.updateGeneralData()
      },
      CNotificaoes() {
        const value = this.optionsLocal.ConfiguracoesSistemaNotificacoes
        this.optionsLocal.ConfiguracoesSistemaNotificacoesEditar = value
        this.updateGeneralData()
      },

      marcarDesmarcarHandler(tipo) {
        if (tipo == 'Sistema') {
          this.CConfiguracoesSistema()
        }
        if (tipo == 'Composição de taxas') {
          this.CComposicaoTaxas()
        }
        if (tipo == 'Parâmetros de Arrecadação') {
          this.CParametroArrecadacao()
        }
        if (tipo == 'Critérios de Elegibilidade') {
          this.CCriteriosElegibilidade()
        }
        if (tipo == 'Indice de Referência') {
          this.CIndiceRef()
        }
        if (tipo == 'Restrições') {
          this.CRestricoes()
        }
        if (tipo == 'Restrições RPPS') {
          this.CRestricoesRPPS()
        }
        if (tipo == 'Notificações') {
          this.CNotificaoes()
        }
        if (tipo == 'Personalização') {
          this.CConfiguracoesPersonalizacao()
        }
        if (tipo == 'Aplicativo') {
          this.CConfiguracoesAplicativo()
        }
        if (tipo == 'Politica Senhas') {
          this.CConfiguracoesPoliticaSenhas()
        }
        if (tipo == 'Lista de Pendências') {
          this.CConfiguracoesListaPendencias()
        }
      },
    },
  }
</script>
<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
